import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from '@reach/router'
import { Button, Col, Form, Input, Modal, notification, Row, Select, Table } from 'antd'
import 'bootstrap/scss/bootstrap.scss'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import client from '../../../api/client'
import StatusTag from '../../../components/base/status'

export default function AdminBulkDetails() {
  const bulkId = useParams()?.bulkId
  const [changeTrackStatusFormRef] = Form.useForm()
  const [changeOrderStatusFormRef] = Form.useForm()
  const [bulkComment, setBulkComment] = useState('')
  const [trackingList, setTrackingList] = useState([])
  const [orderList, setOrderList] = useState([])
  const [trackIdToChangeStatus, setTrackIdToChangeStatus] = useState(null)
  const [changeTrackStatusModalVisible, setChangeTrackStatusModalVisible] = useState(false)
  const [changeOrderStatusModalVisible, setChangeOrderStatusModalVisible] = useState(false)
  const [orderIdToChangeStatus, setOrderIdToChangeStatus] = useState(null)
  const [orderStatuses, setOrderStatuses] = useState([])
  const [trackStatuses, setTrackStatuses] = useState([])
  const [bulkMember, setBulkMember] = useState(null)
  const trackListColumns = [
    {
      title: 'Трак дугаар',
      className: 'text-center',
      dataIndex: 'number',
    },
    {
      title: 'Хүлээн авсан өдөр',
      className: 'text-center',
      dataIndex: 'receivedDate',
      render: (record) => (record ? <span>{moment(record).format('MM/DD/YYYY HH:mm A')}</span> : <span>Хүлээн аваагүй</span>),
    },
    {
      title: 'Тэмдэглэл',
      className: 'text-center',
      dataIndex: 'comment',
    },
    {
      title: 'Статус',
      className: 'text-center',
      responsive: ['sm'],
      width: '200px',
      render: function (record) {
        return (
          <Button
            key={record.id}
            type="link"
            className="w-100"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => {
              setTrackIdToChangeStatus(record.id)
              setChangeTrackStatusModalVisible(true)
              changeTrackStatusFormRef.setFieldsValue({ orderId: record.orderId, trackStatus: record.trackStatus.id })
            }}
          >
            <StatusTag status={record.trackStatus} />
          </Button>
        )
      },
    },
  ]
  const orderListColumns = [
    {
      title: 'Код',
      className: 'text-center',
      dataIndex: 'code',
    },
    {
      title: 'Бүртгэсэн огноо',
      className: 'text-center',
      dataIndex: 'createdDate',
      responsive: ['md'],
      render: (record) => (record ? moment(record).format('MM/DD/YYYY') : ''),
    },
    {
      title: 'Илгээгч',
      className: 'text-center',
      dataIndex: 'sender',
      responsive: ['md'],
      render: (record) => (record ? record : null),
    },
    {
      title: 'Хүлээн авагч',
      className: 'text-center',
      dataIndex: 'receiver',
      responsive: ['md'],
      render: (record) => (record ? record : null),
    },
    {
      title: 'Илгээмжийн төрөл',
      className: 'text-center',
      dataIndex: 'shipmentType',
      responsive: ['md'],
    },
    {
      title: 'Тэмдэглэл',
      className: 'text-center',
      dataIndex: 'customerComment',
      responsive: ['md'],
    },
    {
      title: 'Статус',
      className: 'text-center',
      responsive: ['sm'],
      width: '200px',
      render: (record) => (
        <Button
          key={record.id}
          type="link"
          className="w-100"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => {
            setOrderIdToChangeStatus(record.id)
            setChangeOrderStatusModalVisible(true)
            changeOrderStatusFormRef.setFieldsValue({ orderStatus: record.adminStatus.id })
          }}
        >
          <StatusTag status={record.adminStatus} />
        </Button>
      ),
    },
  ]
  useEffect(() => {
    client
      .get('/api/lookup/trackstatuses')
      .then(async (response) => {
        setTrackStatuses(
          response.data.map((el) => ({
            value: el.id,
            label: el.name,
            description: el.description,
          })),
        )
      })
      .catch((error) => console.error(error))
    client
      .get('/api/lookup/orderadminstatuses')
      .then(async (response) => {
        setOrderStatuses(
          response.data.map((el) => ({
            value: el.id,
            label: el.name,
            description: el.description,
          })),
        )
      })
      .catch((error) => console.error(error))
  }, [])
  const getBulk = useCallback(() => {
    if (bulkId) {
      client.get(`/api/orders/bulkorders/${bulkId}`).then(async (res) => {
        setTrackingList(res.data.tracks)
        setOrderList(res.data.orders)
        setBulkComment(res.data.instruction)
        setBulkMember(res.data.member)
      })
    }
  }, [bulkId])
  const changeTrackStatus = (values) => {
    client
      .patch(`/api/orders/tracks/${trackIdToChangeStatus}/status`, [
        { operationType: 'Add', value: values.trackStatus, path: 'trackStatus', op: 'replace' },
      ])
      .then(() => {
        setChangeTrackStatusModalVisible(false)
        getBulk()
        notification.success({ message: 'Статусыг амжилттай солилоо' })
      })
      .catch((err) => console.error({ err }))
  }
  const changeOrderStatus = (values) => {
    client
      .patch(`/api/orders/${orderIdToChangeStatus}`, [
        { operationType: 'Add', value: orderStatuses.find((x) => x.value === values.orderStatus).description, path: 'AdminStatus', op: 'replace' },
      ])
      .then(() => {
        setChangeOrderStatusModalVisible(false)
        getBulk()
        notification.success({ message: 'Статусыг амжилттай солилоо' })
      })
      .catch((err) => console.error({ err }))
  }
  useEffect(() => {
    getBulk()
  }, [getBulk])
  return (
    <>
      <Helmet title="Багц илгээмж | SB Express" defer={false} />
      <Row>
        <Col span={24}>
          <div>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate('/admin/bulkorders')
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">Багц илгээмж</div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <h4>
            Захиалагч: {bulkMember?.firstName} {bulkMember?.lastName}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <h4>Трак дугаарын мэдээлэл ({trackingList.length})</h4>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <Table
            className="sb-table"
            columns={trackListColumns}
            dataSource={trackingList}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            loading={false}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <h4>Захиалгууд ({orderList.length})</h4>
        </Col>
        <Col span={24}>
          <Table
            className="sb-table mt-3"
            columns={orderListColumns}
            dataSource={orderList}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            loading={false}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <h4>Тайлбар</h4>
        </Col>
        <Col span={10}>
          <Input.TextArea
            rows={4}
            className="sb-shadow sb-input-area"
            placeholder="Нэмэлт тайлбар оруулна уу"
            style={{ fontSize: '20px', padding: '11px' }}
            value={bulkComment}
            readOnly
          />
        </Col>
      </Row>
      <Modal
        title="Трак статус солих"
        open={changeTrackStatusModalVisible}
        onCancel={() => setChangeTrackStatusModalVisible(false)}
        className="sb-modal"
        width={400}
        destroyOnClose
        centered
        footer={[
          <Row justify="center" key="branch-modal">
            <Col span={24}>
              <Button className="btn-sb btn-sb-primary w-100" form="order-status" htmlType="submit">
                Солиx
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="order-status" form={changeTrackStatusFormRef} onFinish={changeTrackStatus}>
          <Form.Item name="trackStatus" className="mb-0">
            <Select
              placeholder="Статус"
              className="sb-select sb-shadow"
              popupClassName="sb-select-dropdown"
              options={trackStatuses}
              suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="text-base" style={{ color: '#11427f' }} />}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Статус солих"
        open={changeOrderStatusModalVisible}
        onCancel={() => setChangeOrderStatusModalVisible(false)}
        className="sb-modal"
        width={400}
        destroyOnClose
        centered
        footer={[
          <Row justify="center" key="branch-modal">
            <Col span={24}>
              <Button className="btn-sb btn-sb-primary w-100" form="order-status" htmlType="submit">
                Солиx
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="order-status" form={changeOrderStatusFormRef} onFinish={changeOrderStatus}>
          <Form.Item name="orderStatus" className="mb-0">
            <Select
              placeholder="Статус"
              className="sb-select sb-shadow"
              popupClassName="sb-select-dropdown"
              options={orderStatuses}
              suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="text-base" style={{ color: '#11427f' }} />}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
